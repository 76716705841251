//media sizes
$tablet-width: 638px;
$desktop-width: 1340px;
//font
$font: "Inter", sans-serif;
//light theame
$White: hsl(0, 0, 100%);
$Very-Pale-white: rgba(255, 255, 255, 0.4);
$Light-Grayish-Blue: hsl(214, 6%, 75%);
$Dark-Grayish-Blue: hsl(228, 12%, 24%);
$Very-Dark-Blue: hsl(230, 17%, 10%);
$Very-Dark-BlueOP: hsla(230, 17%, 10%, 0.8);
$dark-pale-greenBlue: rgba(13, 22, 29, 1); //from template

//dark theame
$Black-op: hsla(0, 0, 0, 0.3);
$Black-op1: hsla(0, 0, 0, 1);
$Black-op2: hsla(0, 0, 0, 0.5);
$Black-op3: hsla(0, 0, 0, 0.7);
$gray: hsl(214, 6%, 12%);

$Dark-Desaturated-Blue: hsl(228, 28%, 20%);
$Desaturated-Blue: hsl(228, 34%, 66%);
$White: hsl(0, 0%, 100%);

//toggle
$Toggle: hsl(230, 12%, 24%);
$Toggle1: hsl(230, 12%, 44%);
//toggle night mode
$Toggle-night: linear-gradient(170deg, hsl(210, 78%, 56%), hsl(146, 68%, 55%));

//primakry colors
$Green: #39a05a;
$Bright-Red: hsl(359, 64%, 55%);
$Light-Blue: hsl(202, 99%, 65%);
$Instagram: linear-gradient(90deg, hsl(37, 97%, 70%), hsl(329, 70%, 58%));
$Discord: hsl(271, 59%, 24%);
$fb: hsl(218, 99%, 53%);
$blue: #005eff;
$logo: #1c8adb;
$very-light-blue: #4187ff;
$yellow: hsl(37, 100%, 64%);
$gold: linear-gradient(to left, #bf953f, #fcf6ba, #fbf5b7, #aa771c);

//units
$ff: 100%;
