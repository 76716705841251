@tailwind base;
@tailwind components;
@tailwind utilities;

/* font family */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@1,800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  font-family: 'Montserrat', sans-serif;
} */

/* new font family */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

body {
  font-family: "Archivo Black", sans-serif;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #afb1ae;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #413f3f;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #404040;
}

/* Hide scrollbar if not hovered */
::-webkit-scrollbar-thumb:vertical {
  min-height: 48px;
  visibility: hidden;
}

::-webkit-scrollbar-thumb:vertical {
  visibility: visible;
}

/* Hide scrollbar if not hovered (Firefox) */
html {
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}

/* chess game styles */
.classicalBoard {
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(8, [col] 5vw);
  grid-template-rows: repeat(8, [row] 5vw);
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

.capablancaBoard {
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(10, [col] 3.29vw);
  grid-template-rows: repeat(8, [row] 3.29vw);
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

.sq {
  position: relative;
  text-align: center;
  cursor: default;
}
.cross-img {
  position: absolute !important;
  top: -10px;
  right: -10px;
  /* width: 10%; */
  width: 40px !important;
}
.wrong-move {
  /* opacity: 0.75; */
  background-color: red !important;
}

.right-move {
  /* opacity: 0.75; */
  background-color: green !important;
}

.square-right-clicked {
  /* background-color: #eb3131 !important; */
  position: relative;
  /* opacity: 0.75; */
  /* content: ""; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
}

.square-right-clicked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust the width of the circle ring */
  height: 80%; /* Adjust the height of the circle ring */
  border: 2px solid #ffffff; /* Adjust the border properties */
  border-radius: 50%;
}

.sq img {
  width: 100%;
  position: relative;
  z-index: 1;
}

.sq img[draggable="true"] {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.sq img[draggable="false"] {
  cursor: default;
}

.sq img[draggable="true"]:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  padding-right: 0.25em;
  width: 105%;
}

.sq span.file {
  position: absolute;
  font-weight: bold;
  font-size: 0.8rem;
  margin-right: 0.15rem;
  margin-bottom: 0.15rem;
  bottom: 0;
  right: 0;
}

.sq span.rank {
  position: absolute;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 0.15rem;
  margin-top: 0.15rem;
  top: 0;
  left: 0;
}

.sq.a1 span,
.sq.c1 span,
.sq.e1 span,
.sq.g1 span,
.sq.i1 span,
.sq.j8 span,
.sq.h8 span,
.sq.f8 span,
.sq.d8 span,
.sq.b8 span {
  color: #ffce9e;
}

.sq.b1 span,
.sq.d1 span,
.sq.f1 span,
.sq.h1 span,
.sq.j1 span,
.sq.i8 span,
.sq.g8 span,
.sq.e8 span,
.sq.c8 span,
.sq.a8 span {
  color: #d18b47;
}

.sq.j2 span,
.sq.j4 span,
.sq.j6 span {
  color: #ffce9e;
}

.sq.a3 span,
.sq.a5 span,
.sq.a7 span {
  color: #ffce9e;
}

.sq.h6 span,
.sq.h4 span,
.sq.h2 span {
  color: #ffce9e;
}

.sq.j3 span,
.sq.j5 span,
.sq.j7 span {
  color: #d18b47;
}

.sq.a2 span,
.sq.a4 span,
.sq.a6 span {
  color: #d18b47;
}

.sq.h7 span,
.sq.h5 span,
.sq.h3 span {
  color: #d18b47;
}

.sq.isSelected {
  background-color: #ffdd43 !important;
  opacity: 0.75;
  box-shadow: inset -4px 0 0 yellow, inset 0 -4px 0 yellow, inset 4px 0 0 yellow,
    inset 0 4px 0 yellow;
}

.sq.isLegal {
  box-shadow: inset -4px 0 0 #ffdd43, inset 0 -4px 0 #ffdd43,
    inset 4px 0 0 #ffeb8e, inset 0 4px 0 #ffeb8e;
  cursor: pointer;
}

.sq.isCheck {
  box-shadow: inset -5px 0 0 rgb(230, 93, 93), inset 0 -5px 0 rgb(230, 93, 93),
    inset 5px 0 0 rgb(230, 93, 93), inset 0 5px 0 rgb(230, 93, 93);
}

.sq.w {
  background-color: #ffce9e;
}

.sq.b {
  background-color: #d18b47;
}

.past {
}

img,
.noTextSelection,
.classicalBoard,
.capablancaBoard {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 900px) {
  .classicalBoard {
    grid-template-columns: repeat(8, [col] 10vw);
    grid-template-rows: repeat(8, [row] 10vw);
  }

  .capablancaBoard {
    grid-template-columns: repeat(10, [col] 9vw);
    grid-template-rows: repeat(8, [row] 9vw);
  }
}

@media (max-width: 600px) {
  .classicalBoard {
    grid-template-columns: repeat(8, [col] 12vw);
    grid-template-rows: repeat(8, [row] 12vw);
  }

  .capablancaBoard {
    grid-template-columns: repeat(10, [col] 9vw);
    grid-template-rows: repeat(8, [row] 9vw);
  }
}

.css-a5rdam-MuiGrid-root > .MuiGrid-item {
  padding-left: 8px;
  background-color: #ecf5ff;
}

/* styles.css */
.blur-background {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.5);
}

/* swal styling */
.swal2-popup {
  font-size: 1.6rem !important;
  font-family: "Poppins", serif;
}

.swal2-title {
  color: #114a82;
}

.swal2-confirm,
.swal2-button {
  background: #0169c2 !important;
}

.historyborder {
  border-radius: 8px;
  border: 1px solid #298bd0;

  background: #fff;

  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
}

.imageborder {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
}

.timeslotborder {
  border-radius: var(--XS, 4px);

  background: #fff;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15) inset;
}
