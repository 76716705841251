.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#grad);
}
.CircularProgressbar-trail {
  stroke: #f1ecec !important;
}

.rbc-calendar {
  width: 105% !important;
}

/* calendar */
/* .App {
  text-align: center;
} */
/* h1 {
  color: rgb(255, 166, 0);
} */

#Calendar {
  /* height: 100%; */
  /* width: 80%; */
  /* margin: auto; */
  /* margin-top: 50px; */
  /* margin-bottom: 50px; */
}

.rbc-month-view {
  /* flex-basis: 500px !important; */
}

.rbc-toolbar-label {
  /* font-weight: bold; */
  /* font-size: 22px; */
  /* color: rgb(37, 66, 197); */
}

/* .rbc-today {
  background-color: rgb(196, 226, 250) !important;
} */
/* 
@media (max-width: 480px) {
  .rbc-toolbar {
    display: block !important;
  }
} */
