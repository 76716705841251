@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/*-------------
    width
---------------- */

$max-width: 107rem;

/*-------------
    color
---------------- */

$main-color: #ffffff;
$primary-color: #4cbefe;
$secondary-color: #1f242e;
$white-color: #ffffff;

/*-------------
    font-size
---------------- */

$text-base: 1rem;
$text-md: 1.5rem;
$text-lg: 1.8rem;
$text-xl: 6rem;
$text-2xl: 8rem;

/* -------------
    font-family
-----------------*/

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

$font-family: "Poppins", sans-serif;
