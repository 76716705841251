@use "../../../stylesheets/variables" as v;
@use "../../../stylesheets/mixins" as m;

.loginSection {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  @include m.fc;
  @include m.cc;
  background-color: v.$Black-op2;
  font-family: v.$font;

  .login {
    text-transform: capitalize;
    width: 90%;
    background-color: v.$gray;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 20px v.$Black-op1;
    color: v.$White;
    border-radius: 0.8rem;

    @include m.desktop {
      width: 25%;
      margin-top: 2rem;
      min-height: 60vh;
    }

    @include m.fc;
    align-items: center;
    justify-content: space-between;

    &>p:nth-of-type(1) {
      text-align: center;
      margin: 1.5rem 0;
      font-size: 1.5rem;
      font-weight: 600;
    }

    &>p:nth-of-type(2) {
      margin-bottom: 1.5rem;
    }

    .form {
      width: 100%;
      @include m.fc;
      @include m.cc;

      .username,
      .pass,
      .email {
        width: v.$ff;
        position: relative;
        margin-bottom: 1.5rem;

        input {
          width: v.$ff;
          height: 3rem;
          padding: 0 3rem;
          color: black;

          &::placeholder {
            text-transform: capitalize;
          }
        }

        .inputIcon {
          position: absolute;
          color: v.$Black-op1;
          left: 5%;
          top: 40%;
        }
      }

      button[type="submit"] {
        text-transform: capitalize;
        border-radius: 0.3rem;
        width: 40%;
        padding: 0.7rem;
        font-size: 1rem;
        font-weight: 600;
        color: v.$White;
        background-color: v.$Light-Blue;
        margin-bottom: 1.5rem;
      }
    }

    .RegisterBtn {
      text-transform: capitalize;
      width: 90%;
      padding: 0.7rem;
      font-size: 1rem;
      font-weight: 600;
      color: v.$White;
      background-color: v.$Black-op1;
      border-radius: 0.3rem;
      margin-bottom: 1.5rem;
    }

    .iconsLogin {
      @include m.fr;
      margin: 1.5rem 0 0;

      .icon {
        font-size: 2rem;
        margin: 0 1rem;
      }
    }
  }

  .forget__form {
    .logo__img {
      max-width: 150px;
      margin-bottom: 1rem;
    }

    .email {
      width: v.$ff;
      position: relative;
      margin-bottom: 1.5rem;

      input {
        width: v.$ff;
        height: 3rem;
        padding: 0 3rem;

        &::placeholder {
          text-transform: capitalize;
        }
      }

      .inputIcon {
        position: absolute;
        color: v.$Black-op1;
        left: 5%;
        top: 40%;
      }
    }

    .form__heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &>p:nth-of-type(1) {
      text-align: left;
      margin: 1.5rem 0;
      font-size: 13px;
      line-height: 1.2rem;
      font-weight: normal;
    }

    .paragraph__text {
      text-align: left;
      margin: 1rem 0;
      font-size: 13px;
      line-height: 1.2rem;
      font-weight: normal;
      color: rgba(255, 255, 255, 0.8);
    }

    .title__text {
      text-align: left;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
    }

    input {
      width: 100%;
      height: 3rem;
      padding: 0 3rem;

      &::placeholder {
        text-transform: capitalize;
      }
    }

    button[type="submit"] {
      text-transform: capitalize;
      border-radius: 0.3rem;
      padding: 0.7rem;
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
      color: v.$White;
      background-color: v.$Light-Blue;
      margin-bottom: 1.5rem;
    }
  }
}