@use "./variables" as v;

@mixin fr {
    display: flex;
    flex-direction: row;
}
@mixin fc {
    display: flex;
    flex-direction: column;
}
@mixin cc {
    justify-content: center;
    align-items: center;
}

@mixin tablet {
    @media (min-width: #{v.$tablet-width}) and (max-width: #{v.$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{v.$desktop-width}) {
        @content;
    }
}
@mixin bs($x, $y, $blur, $c) {
    -webkit-box-shadow: $x $y $blur $c;
    box-shadow: $x $y $blur $c;
}
@mixin  button {
    margin: .5rem;
    width: 7rem;
    height: 2.5rem;
    font-size: .9rem;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: .2rem;
}



//carddesktop component
@mixin info {
    padding: 1rem;
    border-radius: .5rem;
    position: absolute;
    bottom: 0;
    width: v.$ff;
    height: 40%;
    opacity: 1;
    background:linear-gradient(v.$Black-op1, v.$Black-op1);
    transition: .5s ease;
    border-top: 3px solid v.$blue;
    @include fc;
    justify-content: flex-end;
    & > * {
        color: v.$White;
        text-transform: capitalize;
    }

    &>p:nth-of-type(1){
        font-size: 1.3rem;
        font-weight: 600;
    }
    &>p:nth-of-type(2){
        color:v.$blue;
        margin-bottom: 1.5rem;
    }
}

@mixin image {
    width: v.$ff;
    height: v.$ff;
    .img {
        border-radius: .5rem;
        width: v.$ff;
        height: v.$ff; 
    }    
}

@mixin ds1 {
        border-radius: .8rem;
        padding-left: .2rem;
        overflow-y: hidden;
        overflow-x: scroll;
        padding-top: .2rem;
        height: 20rem;
        width: 100%;
        display: grid;
        // grid is deciding how many cards should be desplayed in a row change settings if needed
        grid-template-columns: repeat(9, 12rem);
        grid-template-rows: 94%;
        gap: 1rem;
        position: relative;
        @include desktop {
            gap: 2rem;
        }
        .card {
            border-radius: .5rem;
            @include bs(10px, 0px, 5px, rgba(0,0,0,.3));
            transition: .5s ease;
            position: relative;  
            height: v.$ff;
            .image {
                @include image;
            }
            .info {
                @include info;
            }
            &:hover {
                transform: scale(1.1);
                box-shadow: 0px 0px 5px v.$blue;
            }
        }
}



//shared styles for similer top sections 

@mixin NewSection {
    margin:5.5rem auto 1rem;
    background-color: v.$gray;
    color: v.$White;
    font-family: v.$font;
    padding:1rem;
    @include fc;
    align-items: center;
    position: relative;
    border-radius: .8rem;
    width: 100%;
    overflow: hidden;

    @include desktop {
        margin:4.5rem auto 1rem;
        // margin-left: 4rem;
    }
    
    > h2 {
       margin: 3rem 0 1rem;
       font-size: 1.2rem;
       text-transform: capitalize;
       @include desktop {
          margin: 1.5rem 0 4rem;
          margin-left: 16rem;
          font-size: 1.5rem;
          align-self: flex-start;
       }
    }
} 


@mixin NewSecondarySection {
   margin:1rem auto;
   background-color: v.$gray;
   color: v.$White;
   font-family: v.$font;
   padding: 2rem 0;
   width: 95%; 
   @include fc;
   align-items: center;
   position: relative;
   border-radius: .8rem;
   overflow: hidden;

   @include desktop {
       margin:1rem auto;
       padding:2rem 0 3rem;
       margin-left: 4rem;
   }
}

@mixin h2 {
    text-align: center;
    font-size: 1.4rem   ;
    @include desktop {
        font-size: 1.6rem;
        text-align: center;
    }
    color: v.$White;
    text-transform: capitalize;
}



//ANIMATIONS
@mixin  blink {

    @keyframes blink {
        0% {
            color: transparent;
        }
        10% {
            color: v.$blue;
            text-shadow: 0px 0px 10px v.$blue;
        }
        50% {
            color: transparent;
            text-shadow: none;
        }
        80% {
            color: v.$blue;
            text-shadow: 0px 0px 10px v.$blue;
        }
        90% {
            color: transparent;
            text-shadow: none;
        }
        100% {
            color: v.$blue;
            text-shadow: 0px 0px 10px v.$blue;
        }
    }
}