@forward "./reset";
@use "./variable" as v;
@use "./mixin" as m;

body {
  // @include m.container;
  background-color: v.$main-color;
  color: v.$white-color;
  font-family: v.$font-family;
}
