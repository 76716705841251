.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo {
    animation: logoAnimation 800ms ease-out;
}

@keyframes logoAnimation {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}